<template>
<!-- 稽山街道 -->
    <div>
        <div class="posit-left">
            <div style="margin-bottom:0.23rem;">
                <jishanlefttop></jishanlefttop>
            </div>
            <div class="jishanflex">
                <div>
                    <jishanleft></jishanleft>
                </div>
                <div>
                    <jishanleftright></jishanleftright>
                </div>
            </div>
        </div>
        <div class="posit-right">
            <jishanrighttop style="margin-bottom:0.18rem;"></jishanrighttop>
            <jishanrightbottom></jishanrightbottom>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        jishanlefttop: () => import('../charts/jishantwo/jishanlefttop.vue'),
        jishanleft: () => import('../charts/jishantwo/jishanleft.vue'),
        jishanleftright: () => import('../charts/jishantwo/jishanleftright.vue'),
        jishanrighttop: () => import('../charts/jishantwo/jishanrighttop.vue'),
        jishanrightbottom: () => import('../charts/jishantwo/jishanrightbottom.vue'),
    }
}
</script>

<style scoped>
.jishanflex{
    display: flex;
    justify-content: space-between;
}
</style>